import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './unordered-list.scss';

export function UnorderedListItem({ children, listItemClasses, textClasses, listItemStyles }) {
  return (
    <li className={classnames('unordered-list-item', listItemClasses)} style={listItemStyles}>
      <span className={textClasses}>{children}</span>
    </li>
  );
}

UnorderedListItem.propTypes = {
  children: PropTypes.node,
  listItemClasses: PropTypes.string,
  listItemStyles: PropTypes.objectOf(PropTypes.string),
  textClasses: PropTypes.string,
};

UnorderedListItem.defaultProps = {
  children: null,
  listItemClasses: '',
  textClasses: '',
  listItemStyles: undefined,
};

export function UnorderedListWrapper({
  children,
  listClasses,
  showList,
  showNotAvailable,
  title,
  titleClasses,
  isUnstyled,
}) {
  return (
    <div className="unordered-list">
      {!!title && (
        <p className={titleClasses}>
          {title}
          {showNotAvailable && <span className="fw-normal"> - Not Available</span>}
        </p>
      )}
      {showList && (
        <ul
          className={classnames(listClasses, { 'list-unstyled': isUnstyled })}
          style={isUnstyled ? { listStyle: 'none' } : {}}
        >
          {children}
        </ul>
      )}
    </div>
  );
}

UnorderedListWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  listClasses: PropTypes.string,
  showList: PropTypes.bool,
  showNotAvailable: PropTypes.bool,
  title: PropTypes.string,
  titleClasses: PropTypes.string,
  isUnstyled: PropTypes.bool,
};

UnorderedListWrapper.defaultProps = {
  title: null,
  children: [],
  items: [],
  listClasses: '',
  showList: true,
  showNotAvailable: false,
  titleClasses: '',
  isUnstyled: false,
};

/**
 * UnorderedList component
 * Displays array of strings as unordered list
 * @param title {string} list title
 * @param items {array}
 * @param titleClasses {string} classes for the list title
 * @param listClasses {string} classes for the list items (applies only to bullets if textClasses is defined)
 * @param textClasses {string} classes for the actual text within each list item (if need be different from bullets)
 * @param listItemClasses {string} classes for the each list item
 * @param isUnstyled {bool} is unstyled list
 */
export function UnorderedList({ title, items, titleClasses, listClasses, textClasses, listItemClasses, isUnstyled }) {
  return (
    <UnorderedListWrapper
      showNotAvailable={items.length === 0}
      listClasses={listClasses}
      showList={items.length > 0}
      title={title}
      titleClasses={titleClasses}
      isUnstyled={isUnstyled}
    >
      {items.map(item => (
        <UnorderedListItem key={item} listItemClasses={listItemClasses} textClasses={textClasses}>
          {item}
        </UnorderedListItem>
      ))}
    </UnorderedListWrapper>
  );
}

UnorderedList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  titleClasses: PropTypes.string,
  listClasses: PropTypes.string,
  textClasses: PropTypes.string,
  listItemClasses: PropTypes.string,
  isUnstyled: PropTypes.bool,
};

UnorderedList.defaultProps = {
  title: null,
  items: [],
  titleClasses: '',
  listClasses: '',
  textClasses: '',
  listItemClasses: '',
  isUnstyled: false,
};
